import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { map, retryWhen } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BenefitResponse, PageDefinition } from '../models/page.model';
import { timedRetryStrategy } from './timedRetryStrategy';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  retryStrategy:MonoTypeOperatorFunction<any> = retryWhen(timedRetryStrategy({
    timeoutDuration: environment.http.timeout,
    maxRetryAttempts: environment.http.retry,
    scalingDuration: 500
  }));

  constructor(private httpClient: HttpClient) { }

  public getPages() : Observable<PageDefinition[]> {
    return this.httpClient.get<PageDefinition[]>(`${environment.apiUrl}/Fund/Pages`).pipe(this.retryStrategy);
  }  

  exportMultiFile(body) : Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/Files`, body).pipe(this.retryStrategy);
  }  

  public getBenefitList(): Observable<BenefitResponse> {
    return this.httpClient.get<BenefitResponse>(`${environment.apiUrl}/Member/benefit-list`).pipe(this.retryStrategy);
  }
}