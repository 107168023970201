import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

enum LoggerLevel {
  DEBUG = 1,
  INFO = 2,
  WARN = 3,
  ERROR = 4
}

interface LogRequest {
  level: LoggerLevel,
  exception?: any,
  message: string,
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(private httpClient: HttpClient) { }

  debug(message: string) {
    this.saveLog({ level: LoggerLevel.DEBUG, message });
  }

  info(message: string) {
    this.saveLog({ level: LoggerLevel.INFO, message });
  }

  warning(message: string) {
    this.saveLog({ level: LoggerLevel.WARN, message });
  }

  error(message: string) {
    this.saveLog({ level: LoggerLevel.ERROR, message });
  }

  saveLog(logRequest: LogRequest) {
    // let context = new HttpContext().set(SKIP_LOG, 'true');
    return this.httpClient.post(`${environment.apiUrl}/log`, logRequest).subscribe();
  }
}
