import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoggerService } from "../shared/services/logging/logger.service";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private logger: LoggerService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(error => {
                // Log error
                if (!request.url.endsWith('/log')) {
                    const message = `HttpResponse {Url: "${request?.urlWithParams}", Method: "${request?.method}", Status: ${error?.status}, Body: ${JSON.stringify(request?.body)}, Error: ${error?.message}}`;
                    if (error.status === 400) {
                        this.logger.warning(message);
                    } else {
                        this.logger.error(message);
                    }
                }

                return throwError(error);
            })
        );
    }
}
