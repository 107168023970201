import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Attachment } from 'src/app/member/models/member';
import { PagesSelectors } from 'src/app/pages';
import { PageDefinition } from 'src/app/pages/models';
import { SharedModels } from 'src/app/shared';

interface PreviousRouterState {
  attachmentList: Attachment[];
  page: PageDefinition;
  listLabel: string;
}

interface DocumentViewContent extends SharedModels.FileSnapshot {
  override: {label: string, route: string[], state: PreviousRouterState}
} 

@Component({
  selector: 'psrs-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})
export class DocumentViewComponent implements OnInit {
  form:SharedModels.FileSnapshot;
  subs:Subscription = new Subscription();

  label: string = '';
  route: string[] = [];
  cnt: DocumentViewContent;

  state: PreviousRouterState;

  constructor(private store:Store, private activatedRoute:ActivatedRoute, private router:Router) { 
    this.cnt = this.router.getCurrentNavigation().extras.state as DocumentViewContent;
  }

  ngOnDestroy() {    
    this.subs.unsubscribe();
  }  

  ngOnInit(): void {
    var pageId = this.activatedRoute.snapshot.params['id']; 
    this.subs.add(
      this.store.select(PagesSelectors.getPage, { id: pageId }).subscribe(pg => {
        this.label = pg.navigation;
        this.route = ['/pages', pg.id];

        if (this.cnt.override?.label) {
          this.label = this.cnt.override?.label;
        }
        if (this.cnt.override?.route) {
          this.route = this.cnt.override?.route;
        }
        if (this.cnt.override?.state) {
          this.state = this.cnt.override?.state;
        }
      })
    );
  }
}