import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMember from './member.reducer';
import { MemberState } from './member.state';

const selectMemberState = createFeatureSelector<MemberState>(fromMember.memberFeatureKey);
const selectMemberErrorState = createSelector(selectMemberState, state => state.error);
const selectMemberDataState = createSelector(selectMemberState, state => state.data);

export const getMemberError = createSelector(selectMemberErrorState, state => state);

export const getMember = createSelector(selectMemberDataState, state => state.core);
export const getSignature = createSelector(selectMemberDataState, state => state.signature);
export const getMetadata = createSelector(selectMemberDataState, (state, props) => {
    return state.new ? state.new[props.itemKey] : undefined;
});
export const getMetadataAll = createSelector(selectMemberDataState, (state) => {
    return state.new ? state.new : undefined;
});
export const resolveMetadata = createSelector(selectMemberDataState, (state, fullyQualifiedPropertyKey:string) => {
    if(!fullyQualifiedPropertyKey) {
        return null;
    }
    let keyParts = fullyQualifiedPropertyKey.split('.');
    let mdItemKey = keyParts[0];
    let mdPropKey = keyParts[1];

    let mdItem = state.new[mdItemKey];
    let mdProp = (!mdItem) ? null : mdItem[mdPropKey];

    return mdProp;    

});

export const getDrop = createSelector(selectMemberDataState, state => state.drop);
export const getFiscal = createSelector(selectMemberDataState, state => state.fiscal);
export const getBenefit = createSelector(selectMemberDataState, state => state.benefit);
export const getBeneficiary = createSelector(selectMemberDataState, state => state.beneficiary);
export const getAddress = createSelector(selectMemberDataState, state => state.address);

export const getMemberStatus = createSelector(selectMemberDataState, state => state.core?.status.toLocaleLowerCase());

export const hasSpecificData = createSelector(selectMemberDataState, state => {
    var hasData = { 
        hasDrop: false,
        hasFiscal: false,
        hasBenefit: false
    };

    if(state.drop) {
        hasData.hasDrop = true;
    }

    if(state.fiscal) {
        hasData.hasFiscal = true;
    }

    if(state.benefit) {
        hasData.hasBenefit = true;
    }

    return hasData;
});
export const getOptInMessage = createSelector(selectMemberDataState, state => state.lstMemberNotificationOptIns);
export const setOptInMessage = createSelector(selectMemberDataState, state => {
    return state;
});
export const generatePaymentInfo = createSelector(selectMemberDataState, state => state.paymentHistoryResponse);

export const selectAttachments = createSelector(selectMemberDataState, state => state.attachments);
export const downloadedAttachment = createSelector(selectMemberDataState, state => state.downloadedAttachment);
