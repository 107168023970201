<div class="deskto_p">
  <div class="mob_pad">
  <a [routerLink]="backToRoute" [state]="backToRouteState" class="bk"
    ><fa-icon [icon]="faChevron" class="faIcon"></fa-icon>Back to
    {{ backToLabel }}</a
  >
  <div class="inline_display" id="detailDisplay ">
    <ng-content></ng-content>
  </div>
</div>
<!-- <div class="inline_display" id="detailDisplay ">
  <ng-content></ng-content>
</div> -->
</div>