import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { FileSignature } from 'src/app/shared/models/file-signature';
import { PageDefinition } from '../models';
import { BenefitResponse } from '../models/page.model';

export const loadPages = createAction('[Pages Data] Load Pages');
export const loadPagesSuccess = createAction('[Pages Data] Load Pages Success', props<{ pages: PageDefinition[] }>());
export const loadPagesFailure = createAction('[Pages Data] Load Pages Failure', props<{ error: HttpErrorResponse }>());

export const signAttachment = createAction('[Pages Data] Sign Attachment', props<{ pageId: string, fileKey: string }>());
export const signAttachmentSuccess = createAction('[Pages Data] Sign Attachment Success', props<{ pageId: string, fileKey: string, signature: FileSignature }>());
export const signAttachmentFailure = createAction('[Pages Data] Sign Attachment Failure', props<{ pageId: string, fileKey: string, error: HttpErrorResponse }>());

export const getBenefitList = createAction('[GET/API] Benefit List');
export const getBenefitListSuccess = createAction('[GET/API] Benefit List Success', props<{ response: BenefitResponse }>());
export const getBenefitListFailure = createAction('[GET/API] Benefit List Failure');
