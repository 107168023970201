export enum PropertyType {
  Text = 1,
  Email = 2,
  Phone = 3,
  Whole_Number = 4,
  Currency = 5,
  Decimal = 6,
  Date = 7,
  Lookup = 50,
  Address = 100,
  Person_Name = 101,
  Employer = 51,
  Binary = 9,
  Percentage = 10,
  SSN = 11,
  RichText = 12,
  Aggregation = 103,
  Elapsed_Time = 14,
  DateTime = 8,
  Department = 52,
  Benefit = 53,
  Tier = 55,
}