<!-- <div class="mainContent"> -->
<div class="deskto_p scroll">
  <div class="row"></div>
  <div class="member_det mob_pad">
    <h1 style="font-size: 24px">{{ page.header }}</h1>
    
    <ng-container *ngFor="let content of page.content">
      <ng-container [ngSwitch]="content.key">
        <div
          class="row genericCallbox"
          *ngSwitchCase="TEMPLATE_KEY.SupportContact"
        >
          <callbox *ngIf="content?.value">{{ content?.value }}</callbox>
          <!-- <div
            class="header_banner_info"
            style="background: url(./assets/images/info.png)"
          >
            <h2 *ngIf="content.value?.length">
              {{ content.value }}
              <a href="tel:(617) 977-8408">(123) 456-7890</a>
            </h2>
          </div> -->
        </div>
        <div *ngIf="!showPdfPayroll">
          <div class="row" *ngSwitchCase="TEMPLATE_KEY.PayrollHistoryTable">
            <div class="benefit-box" *ngIf="benefits.length">
              <p class="title">Benefit: </p>
              <select
                *ngIf="benefits.length > 1"
                class="benefit-select"
                (change)="benefitOnChange($event)"
              >
                <ng-container *ngFor="let benefit of benefits;">
                  <option [value]="benefit.benefitId">{{ benefit.benefitName }}</option>
                </ng-container>
              </select>
              <p *ngIf="benefits.length === 1" class="title" style="font-weight: 400;">{{selectedBenefit.benefitName}}</p>
            </div>
            <div *ngIf="content.value[0] !== true">
              <div class="flex-header">
                <select
                  class="year-selection"
                  #t
                  (change)="selectOnChange(t.value)"
                >
                  <option>All</option>
                  <option
                    *ngFor="let year of years"
                    [value]="year"
                    [selected]="year == yearFilter"
                  >
                    {{ year }}
                  </option>
                </select>
                <!-- <button [disabled] = "isDisabled" (click)="changeShowPdf(true)">Test</button> -->
                <button
                  *ngIf="fundType === FundType.ChicagoLabor"
                  [disabled]="isDisabled"
                  class="btns"
                  (click)="viewPaymentAdvice()"
                >
                  <!-- <fa-icon [icon]="faCoffee"></fa-icon> -->
                  <!-- <img [src]="isDisabled ? 'assets/images/picture_as_pdf_gray.svg' : 'assets/images/picture_as_pdf.svg'"/> -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="22"
                    viewBox="0 0 17 22"
                    fill="none"
                  >
                    <rect
                      x="2.86963"
                      y="7.51123"
                      width="12.3247"
                      height="11.5181"
                      fill="white"
                    />
                    <path
                      d="M4.42603 17.6565C4.42603 17.6565 4.9565 17.4427 5.82743 16.073C5.263 16.506 4.7872 17.0436 4.42603 17.6565Z"
                      fill="#F20707"
                    />
                    <path
                      d="M16.0886 4.71123L12.1299 0.752462C11.9483 0.573957 11.7036 0.474365 11.449 0.475349H11.2114V5.60591H16.342V5.36047C16.342 5.11993 16.2516 4.88816 16.0886 4.71123Z"
                      fill="#F20707"
                    />
                    <path
                      d="M10.9184 6.91241C10.6622 6.91241 10.4165 6.81064 10.2354 6.62949C10.0542 6.44834 9.95245 6.20265 9.95245 5.94647V0.467529H1.93199C1.80551 0.467525 1.68028 0.492565 1.56353 0.541204C1.44678 0.589844 1.34081 0.66112 1.25175 0.750921C1.16268 0.840722 1.09228 0.94727 1.0446 1.06442C0.996926 1.18157 0.972918 1.307 0.973963 1.43347V20.0555C0.968624 20.1846 0.989455 20.3135 1.0352 20.4343C1.08095 20.5552 1.15067 20.6655 1.24017 20.7587C1.32967 20.8519 1.4371 20.926 1.55599 20.9766C1.67488 21.0272 1.80277 21.0532 1.93199 21.0531H15.3918C15.5183 21.0531 15.6435 21.0281 15.7603 20.9795C15.877 20.9308 15.983 20.8595 16.072 20.7697C16.1611 20.6799 16.2315 20.5734 16.2792 20.4562C16.3269 20.3391 16.3509 20.2137 16.3498 20.0872V6.91241H10.9184ZM12.7553 15.8276C11.8516 15.7594 10.9749 15.4888 10.19 15.0358C9.11357 15.272 8.05525 15.5843 7.02296 15.9701C6.0016 17.7198 5.28902 18.4403 4.57644 18.4403C4.41351 18.435 4.25328 18.3972 4.1051 18.3293C3.95693 18.2613 3.82378 18.1645 3.71343 18.0445C2.92168 17.1023 4.80605 15.8751 5.89867 15.3208C6.50086 14.2041 7.04525 13.0572 7.52969 11.8846C7.18142 10.8949 7.07023 9.83728 7.20507 8.79678C7.29346 8.59654 7.44478 8.43062 7.63605 8.3242C7.82732 8.21777 8.04809 8.17667 8.26484 8.20712C8.4816 8.23758 8.6825 8.33792 8.83703 8.49293C8.99157 8.64793 9.09131 8.84913 9.12111 9.06598C9.23913 9.93116 9.15512 10.8119 8.87567 11.6392C9.17276 12.5389 9.77855 13.3046 10.5859 13.8007C11.3776 13.6977 12.9611 13.5394 13.5391 14.0857C13.6776 14.2351 13.7689 14.4221 13.8018 14.6231C13.8347 14.8242 13.8077 15.0305 13.7241 15.2162C13.6405 15.402 13.504 15.5591 13.3317 15.6678C13.1594 15.7765 12.9589 15.8321 12.7553 15.8276Z"
                      fill="#F20707"
                    />
                    <path
                      d="M8.18677 8.88379C8.05776 9.50886 8.08496 10.1561 8.26595 10.7682C8.48764 10.3644 8.51931 8.88379 8.18677 8.88379Z"
                      fill="#F20707"
                    />
                    <path
                      d="M8.19464 12.6523C7.89211 13.5195 7.52147 14.3614 7.08618 15.1701C7.89954 14.8008 8.74495 14.5066 9.61188 14.2913C9.03047 13.8496 8.54774 13.2914 8.19464 12.6523Z"
                      fill="#F20707"
                    />
                    <path
                      d="M11.4014 14.5764C12.8899 15.2098 13.1116 14.9406 13.1116 14.9406C13.2778 14.8298 13.0165 14.4656 11.4014 14.5764Z"
                      fill="#F20707"
                    />
                  </svg>
                  View Payment Advice
                </button>
              </div>
            </div>
            <ng-container>
              <div class="table-history pay_table">
                <div class="table_scroll">
                  <table>
                    <tr>
                      <th style="width: 7%" *ngIf="fundType === FundType.ChicagoLabor">
                        <input
                          type="checkbox"
                          (change)="onChange(-1, allSelected)"
                          [checked]="allSelected"
                        />
                      </th>
                      <th style="width: 30%">Payment Date</th>
                      <th style="width: 35%; text-align: right">
                        Gross Payment
                      </th>
                      <th style="width: 35%; text-align: right">Net Payment</th>
                    </tr>
                    <tr *ngFor="let item of payments; let i = index">
                      <td *ngIf="fundType === FundType.ChicagoLabor">
                        <label class="form-controls"
                          ><input
                            type="checkbox"
                            [checked]="item.checked"
                            (change)="onChange(i, item.checked)"
                        /></label>
                      </td>
                      <td>{{ item.payableDate | date: 'MM/dd/yyyy' }}</td>
                      <td style="text-align: right">
                        <span>$</span>{{ item.gross | number: '1.2-2' }}
                      </td>
                      <td style="text-align: right">
                        <span>$</span>{{ item.netPayment | number: '1.2-2' }}
                      </td>
                    </tr>
                    <tr
                      style="text-align: center"
                      *ngIf="payments.length === 0"
                    >
                      <td colspan="4">No data to display</td>
                    </tr>
                  </table>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <!-- <div *ngIf="showPdfPayroll">
      <psrs-display-payroll-pdf
        [pdfUrl]="pdfUrl"
        (closeShowPdf)="closeShowPdf()"
      ></psrs-display-payroll-pdf>
    </div> -->

    <psrs-desktop-pdf-modal
      *ngIf="isPdfModalOpen === true"
      [modalHeading]="modalLabel"
      [pdfUrl]="selectedPdfUrl"
      (closeModal)="closePdfModal()"
      (pdfDownload)="downloadPdf(selectedPdfUrl)"
    ></psrs-desktop-pdf-modal>
  </div>
</div>
