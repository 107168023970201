import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {
  IconDefinition,
  faHeadset,
  faFilePdf,
  faFileDownload
} from '@fortawesome/free-solid-svg-icons'
import { Store } from '@ngrx/store'
import { PagesDataActions, PagesModels } from 'src/app/pages'
import { LoggingService } from 'src/app/shared'
import { FileSnapshot } from 'src/app/shared/models'
import { SignatoryService } from 'src/app/shared/services/signatory-service'

@Component({
  selector: 'psrs-document-list-template',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {
  faFileDownload: IconDefinition = faFileDownload
  faHeadset: IconDefinition = faHeadset
  faFilePdf: IconDefinition = faFilePdf
  isPdfModalOpen = false
  selectedPdfUrl: string
  downloadPdfData: any = null
  fileName: any
  modalLabel: any

  private _page: PagesModels.PageDefinition

  @Input() set page (value: PagesModels.PageDefinition) {
    this._page = value
    this.redrawComponent()
  }

  get page (): PagesModels.PageDefinition {
    return this._page
  }

  contentList: any[] = []

  constructor (
    private store: Store,
    private loggingService: LoggingService,
    private router: Router,
    private signatoryService: SignatoryService
  ) {}

  ngOnInit (): void {}

  redrawComponent (): void {
    let result = {}
    this.page.content.forEach(cnt => {
      for (var idx in cnt.value) {
        if(idx !== "$id"){
          if (result[idx] == null) {
            result[idx] = {}
          }
          result[idx][cnt.key] = cnt.value[idx]
        }        
      }
    })
    this.contentList = []
    for (let idx in result) {
      this.contentList.push(result[idx])
    }
    this.contentList.forEach(cnt => {
      this.signatoryService.getUrl(cnt.file, fileKey => {
        this.store.dispatch(
          PagesDataActions.signAttachment({ pageId: this.page.id, fileKey })
        )
      })
    })
    // console.log(this.contentList, 'list')
  }

  displayPdf (idx): void {
    let cnt = this.contentList[idx]
    this.router.navigate(['pages', this.page.id, 'document'], {
      state: cnt.file,
      fragment: window.location.getClientDomain()
    })
  }

  downloadPdf (form: FileSnapshot): void {
    var downloadUrl = this.signatoryService.getUrl(form)
    if (downloadUrl) {
      this.loggingService.logEvent('User Downloaded Pdf')
      window.open(downloadUrl, '_blank')
    }
  }

  displayFileName (fileName: any): string {
    let idxFileExt = fileName.lastIndexOf('.') - fileName.length
    return fileName.slice(0, idxFileExt)
  }
  displayDesktopPdf (index: number): void {
    let cnt = this.contentList[index]

    this.fileName = cnt.file.fileName

    this.selectedPdfUrl = this.contentList[index]?.file?.uri
    this.downloadPdfData = this.contentList[index]?.file
    this.isPdfModalOpen = true
    this.modalLabel = cnt.file.label.split('.pdf')[0]
  }
  closePdfModal () {
    this.isPdfModalOpen = false
    this.selectedPdfUrl = ''
  }
}
