<div class="row no-gutters">

  
  <div class="labl">
  <div class="col"><label class="form-label" required>{{ name }}</label>
    <div class="col-1 dk"><fa-icon *ngIf="helpText" (click)="showHelpText();" [icon]="faQuestionCircle" class="helpIcon fa-lg"></fa-icon></div>
  
  
  </div>
  <div class="w-100"></div>
  <div class="col-1 mb"><fa-icon *ngIf="helpText" (click)="showHelpText();" [icon]="faQuestionCircle" class="helpIcon fa-lg"></fa-icon></div>
</div>
  <div class="col">
      <select class="form-control" (change)="formFieldChanged($event)">
        <option value=""></option>
        <option [value]="opt.code" *ngFor="let opt of listOptions" [selected]="opt.code === defaultValue">{{ displayOption ? opt[displayOption] : opt.code }}</option>
      </select>
  </div>
  <div class="w-100"></div>
  <div class="col offset-1 abs_l" *ngIf="!isValid">
    <small class="error">You must select a value.</small>
  </div>
  <ng-template #helpTextModal let-modal *ngIf="helpText">
    <div class="modal-header">
      <h4 class="modal-title">{{ name }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div [innerHtml]="safeHtml(helpText)"></div>
    </div>
    <div class="modal-footer">
      <button ngbAutofocus type="button" class="btn btn-modal btn-lg" (click)="modal.close('Close click')">Close</button>
    </div>
  </ng-template>
</div>

