<div class="row no-gutters">
  <div class="age_grd dk">
  <div class="col-4">
    <!-- <label class="form-label">{{ name }}</label> -->
    <div class="a_ge"><label class="form-label">{{ name }}</label>
     
      <div class="ic_on"><fa-icon *ngIf="helpText" (click)="showHelpText();" [icon]="faQuestionCircle" class="helpIcon fa-lg"></fa-icon>
      
  
      </div>
    
    </div>
 
  <!-- <div class="col{{ useGender ? '-4' : '' }} mx-2"><label class="form-label no-increment {{ !hasBeneficiary ? 'text-muted': '' }}">Current Age of Beneficiary</label></div> -->
  <!-- <div class="col" *ngIf="useGender"><label class="form-label no-increment {{ !hasBeneficiary ? 'text-muted': '' }}">Gender of Beneficiary</label></div> -->
  <!-- <div class="w-100"></div> -->

  <!-- <div class="col-1"><fa-icon *ngIf="helpText" (click)="showHelpText();" [icon]="faQuestionCircle"
      class="helpIcon fa-lg"></fa-icon></div> -->
  

    <select class="form-control" (change)="formFieldChanged($event);" #toggle>
      <option>No</option>
      <option [selected]="hasBeneficiary">Yes</option>
    </select>

  </div>




  <div class="col{{ useGender ? '-4' : '' }} mx-2">
    <div class="col{{ useGender ? '-4' : '' }} a_ge"><label
        class="form-label no-increment {{ !hasBeneficiary ? 'text-muted': '' }}">Current Age of Beneficiary</label>
    </div>

    <div class="input-group mb-2">

      <input type="number" class="form-control" [readonly]="!hasBeneficiary" (change)="formFieldChanged($event);"
        [value]="defaultValue?.age" #age positiveNumber>
      <!-- <div class="input-group-prepend">
        <div class="input-group-text">y.o.</div>
      </div> -->
    </div>
    <div class="col offset-1 abs_l" *ngIf="hasValidationErrors">
      <small class="error" *ngIf="!hasAge">You must specify the Age of your Beneficiary.</small>
    </div>
  </div>
  <div class="col" *ngIf="useGender">
    <div class="a_ge" *ngIf="useGender"><label
        class="form-label no-increment {{ !hasBeneficiary ? 'text-muted': '' }}">Gender of Beneficiary</label></div>
    <select class="form-control" [disabled]="!hasBeneficiary" (change)="formFieldChanged($event);" #gender>
      <option></option>
      <option value="M" [selected]="defaultValue?.gender === 'M'">Male</option>
      <option value="F" [selected]="defaultValue?.gender === 'F'">Female</option>
    </select>
    <div class="col offset-1 abs_l" *ngIf="hasValidationErrors">
      <small class="error" *ngIf="!hasGender && useGender">You must specify the Gender of your Beneficiary.</small>
    </div>
  </div>
</div>

  <div class="w-100"></div>



  <ng-template #helpTextModal let-modal *ngIf="helpText">
    <div class="modal-header">
      <h4 class="modal-title">{{ name }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div [innerHtml]="safeHtml(helpText)"></div>
    </div>
    <div class="modal-footer">
      <button ngbAutofocus type="button" class="btn btn-modal btn-lg"
        (click)="modal.close('Close click')">Close</button>
    </div>
  </ng-template>  
</div>






<!-- New Code -->
