<div class="deskto_p">
  <div class="homes">
    <h1 style="background-image: url(./assets/images/home_bg.svg);">{{ page.header }}</h1>

    <ng-container *ngFor="let content of page.content">
      <ng-container [ngSwitch]="content.key">
        <div class="row genericCallbox" *ngSwitchCase="TEMPLATE_KEY.SupportContact">
            <callbox *ngIf="content.value?.length">{{ content.value }}</callbox>
        </div>
        <div class="row imgs" *ngSwitchCase="TEMPLATE_KEY.Image">
          <div class="welcome_img">
            <img [src]="content.value.uri" />
            </div>
        </div>
        <div class="ql-editor">
            <p
            class="row genericBody"
            *ngSwitchCase="TEMPLATE_KEY.Body"
            [innerHtml]="safeHtml(content.value)"
            ></p>
        </div>

        <div class="btns" *ngSwitchCase="TEMPLATE_KEY.Attachment">
          <a href="" (click)="$event.preventDefault(); downloadPdf(content.value)"><img src="./assets/images/download.svg" />Download</a>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
