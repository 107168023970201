import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { PagesModels } from 'src/app/pages'
import { LoggingService } from 'src/app/shared'

@Component({
  selector: 'psrs-drilldown-template',
  templateUrl: './drilldown.component.html',
  styleUrls: ['./drilldown.component.scss']
})
export class DrilldownComponent implements OnInit {
  private _page: PagesModels.PageDefinition

  @Input() set page (value: PagesModels.PageDefinition) {
    this._page = value
    this.redrawComponent()
  }

  get page (): PagesModels.PageDefinition {
    return this._page
  }

  contentList: any[] = []
  staffData: any

  constructor (private router: Router, private sanitized: DomSanitizer) {}

  ngOnInit (): void {}

  redrawComponent (): void {
    let result = {}
    this.page.content.forEach(cnt => {
      for (var idx in cnt.value) {
        if (result[idx] == null) {
          result[idx] = {}
        }
        result[idx][cnt.key] = cnt.value[idx]
      }
    })

    this.contentList = []
    Object.entries(result).filter(([key, value]) => key !== '$id').map(([key, value])=> this.contentList.push(value));

    this.staffData = { ...this.contentList[0] }

    if (window.innerWidth >= 781) {
      this.desktopCardClick(0)
    }
  }

  cardClicked (idx): void {
    let cnt = this.contentList[idx]
    this.router.navigate(['pages', this.page.id, 'drilldown'], {
      state: cnt,
      fragment: window.location.getClientDomain()
    })
  }

  desktopCardClick (idx): void {
    let cnt = this.contentList[idx]
    cnt.index = idx

    if (window.innerWidth <= 768) {
      this.cardClicked(this.contentList.indexOf(cnt))
    } else {
      this.staffData = { ...cnt }
    }
  }
  safeHtml (html: string): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(html)
  }
}
