<!-- Desktop View -->
<div class="deskto_p">
  <div class="mob_pad">
    <div class="row">
      <h1>{{ page.header }}</h1>
      <!-- <h1>{{ page.header }}</h1> -->
    </div>
    <div class="row mb">
      <p class="instruction">Click below for more detail</p>
    </div>
    <div class="staff grid">
      <div id="proScroller" *ngIf="contentList?.length" class="staff_sum">
        <div
          class="proCard"
          style="background-image: url(./assets/images/rt_arr.svg)"
          [class.selectedRow]="contentList.indexOf(cnt) === staffData.index"
          (click)="desktopCardClick(contentList.indexOf(cnt))"
          *ngFor="let cnt of contentList"
        >
          <div class="dest_img">
            <img
              [src]="cnt.image.uri"
              title="{{ staffData.primary }}"
              class="img_dril"
              *ngIf="cnt.image"
            />
            <img
              src="./assets/images/user.png"
              *ngIf="!cnt.image"
              title="{{ staffData.primary }}"
            />
          </div>
          <div>
            <p class="hed">
              <strong>{{ cnt?.primary }}</strong>
            </p>
            <p class="con_t">
              <em> {{ cnt?.secondary }}</em>
              <!-- {{ cnt.tertiary }} -->
            </p>
          </div>
        </div>
      </div>

      <!-- Conditionally render staff data if available -->
      <ng-container *ngIf="staffData">
        <div class="row proHeader staff_del">
          <div class="img_designation">
            <div class="dest_img">
              <img
                [src]="staffData.image?.uri"
                title="{{ staffData.primary }}"
                *ngIf="staffData.image"
              />
              <img
                src="./assets/images/user.png"
                *ngIf="!staffData.image"
                title="{{ staffData.primary }}"
              />
            </div>
            <div class="phar">
              <strong>{{ staffData.primary }}</strong>
              <span class="company"
                ><em>{{ staffData.secondary }}</em
                >,
                <span class="det_l"><br />{{ staffData.tertiary }} </span></span
              >
              <div
                class="proBody"
                [innerHtml]="safeHtml(staffData.details)"
                *ngIf="staffData.details"
              ></div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
