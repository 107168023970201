import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { DocumentType } from 'src/app/shared/models';
import * as MemberActions from './member.actions';
import { MemberDataState, MemberState } from './member.state';
import { MemberInfo } from '../models/member';
import { PropertyType } from 'src/app/client/types';

export const memberFeatureKey = 'member';

export const initialState: MemberDataState = {};

const errorReducer = createReducer({ }, 

  on(MemberActions.loadMetadataFailure, (state, { itemKey, error }) => {
    return {
      itemKey,
      error
    }
  }),  
  on(MemberActions.loadMemberFailure, (state, { id, error }) => {
    return {
      action: 'loadMember',
      error: error
    }
  }),
  on(MemberActions.loadDropFailure, (state, { id, error }) => {
    return {
      action: 'loadDrop',
      error: error
    }
  }),
  on(MemberActions.loadBenefitFailure, (state, { id, error }) => {
    return {
      action: 'loadBenefit',
      error: error
    }
  }),
  on(MemberActions.loadFiscalFailure, (state, { id, error }) => {
    return {
      action: 'loadFiscal',
      error: error
    }
  }),
  on(MemberActions.loadBeneficiaryFailure, (state, { id, error }) => {
    return {
      action: 'loadBeneficiary',
      error: error
    }
  }),
  on(MemberActions.loadAddressFailure, (state, { id, error }) => {
    return {
      action: 'loadAddress',
      error: error
    }
  })
);

const dataReducer = createReducer(initialState,
  on(MemberActions.loadMemberSuccess, (state, { member, id }) => {
    
    const propertyList = member?.memberInfo?.propertyList;
    const propertyTable = member?.memberInfo?.propertyTable;
    const retirementCalc = member?.memberInfo?.retirementCalc;
    const propertyListKeys = Object.keys(propertyList);
    
    const propertyListResult = propertyListKeys.reduce((acc, key) => {
      const parts = key.split('.');
      const countCurrentKey = propertyListKeys.filter((item => {
        return (item.split('.')[0] === parts[0] && !item?.[3]) || (item.split('.')[0] === parts[0] && item.split('.')?.[3] === parts?.[3]);
      }));

      if (Object.keys(acc).find(accKey => (accKey.split('.')[0] === key.split('.')[0]) && (accKey.split('.')[3] === key.split('.')[3]))) {
        return acc;
      }

      if(countCurrentKey?.length > 1) {
        const resultValue = {};
        
        countCurrentKey.forEach((crrKey) => {
          const optionKey = crrKey.split('.')[1].split('_')[1];
          if (propertyList[crrKey]) {
            resultValue[optionKey.toLowerCase()] = propertyList[crrKey];
          }
        })
        
        return { ...acc, [`${parts[0]}.${parts[1].split('_')[0]}.${parts[2]}${parts?.[3] ? `.${parts[3]}` : ''}`]: resultValue};
      } else {
        return { ...acc, [key]: propertyList[key]};
      }
    }, {});
    const propertyTableResult = propertyTable.map((item) => {
      const propertyTableKeys = Object.keys(item);

      return propertyTableKeys.reduce((acc, key) => {
        const parts = key.split('.');
        const countCurrentKey = propertyTableKeys.filter((item => {
          return (item.split('.')[0] === parts[0] && !item?.[3]) || (item.split('.')[0] === parts[0] && item.split('.')?.[3] === parts?.[3]);
        }));
  
        if (Object.keys(acc).find(accKey => (accKey.split('.')[0] === key.split('.')[0]) && (accKey.split('.')[3] === key.split('.')[3]))) {
          return acc;
        }
  
        if(countCurrentKey?.length > 1) {
          const resultValue = {};
          
          countCurrentKey.forEach((crrKey) => {
            const optionKey = crrKey.split('.')?.[1].split('_')?.[1];
            if (item[crrKey]) {
              resultValue[optionKey.toLowerCase()] = item[crrKey];
            }
          })
          
          return { ...acc, [`${parts?.[0]}.${parts?.[1].split('_')?.[0]}.${parts[2]}${parts?.[3] ? `.${parts[3]}` : ''}`]: resultValue};
        } else {
          return { ...acc, [key]: item[key]};
        }
      }, {})
    });
    const retirementCalcResult = Object.keys(retirementCalc).reduce((acc, key) => {
      const retirementCalcValue = retirementCalc?.[key];

      if (+key.split('.')[2] === PropertyType.Date) {
        return {...acc, [key]: retirementCalcValue ? (new Date(retirementCalcValue)) : null};
      }
      return {...acc, [key]: retirementCalcValue};
    }, {});
    
    var coreMem = {
      id,
      ...member,
      memberInfo: {
        propertyList: propertyListResult,
        propertyTable: propertyTableResult,
        retirementCalc: retirementCalcResult
      }
    };
    return {
      ...state,
      core: coreMem as MemberInfo
    };
  }),
  on(MemberActions.loadMetadataSuccess, (state, { itemKey, metadata }) => {
    let updatedMetadata = {
      ...state.new
    };
    updatedMetadata[itemKey] = metadata;
    
    return {
      ...state,
      new: updatedMetadata
    };
  }),
  on(MemberActions.signAttachmentSuccess, (state, { itemKey, signature }) => {
    let result = {
      ...state,
      signature
    };
    return result;
  }),  
  on(MemberActions.loadDropSuccess, (state, { drop }) => {
    var coreDrop = {
      ...drop,
      statementDate: new Date(drop.statementDate)
    };

    if(drop.entranceDate) {
      coreDrop.entranceDate = new Date(drop.entranceDate);
    }

    if(drop.exitDate) {
      coreDrop.exitDate = new Date(drop.exitDate);
    }

    return {
      ...state,
      drop: coreDrop
    };
  }),
  on(MemberActions.loadBenefitSuccess, (state, { benefit }) => {
    var coreBenefit = {
      ...benefit,
      valuationDate: new Date(benefit.valuationDate)
    };

    return {
      ...state,
      benefit: coreBenefit
    };
  }),
  on(MemberActions.loadFiscalSuccess, (state, { fiscal }) => {
    return {
      ...state,
      fiscal: fiscal
    };
  }),
  on(MemberActions.loadBeneficiarySuccess, (state, { beneficiary }) => {
    var coreBen = {
      ...beneficiary
    };
    if(beneficiary.birthday) {
      coreBen.birthday = new Date(beneficiary.birthday);
    }

    return {
      ...state,
      beneficiary: coreBen
    };
  }),
  on(MemberActions.loadAddressSuccess, (state, { address }) => {
    return {
      ...state,
      address
    };
  }),
  on(MemberActions.signFileSuccess, (state, { signature, docType }) => {
    if(docType === DocumentType.Benefit) {
      return {
        ...state,
        benefit: {
          ...state.benefit,
          attachment: {
            ...state.benefit.attachment,
            signature: signature
          }
        }
      };
    } else if(docType === DocumentType.DROP) {
      return {
        ...state,
        drop: {
          ...state.drop,
          attachment: {
            ...state.drop.attachment,
            signature: signature
          }
        }
      };
    }
  }),
  on(MemberActions.setOptInMessage, (state) => {
    return {
      ...state,
    };
  }),

  on(MemberActions.loadOptInMessageSuccess, (state, {lstMemberNotificationOptIns}) => {
    return {
      ...state,
      lstMemberNotificationOptIns: lstMemberNotificationOptIns
    };
  }),

  on(MemberActions.generatePaymentInfoSuccess, (state, {paymentHistoryResponse}) => {
    return {
      ...state,
      paymentHistoryResponse: paymentHistoryResponse
    };
  }),

  //#region 139593: Get attachment list + Download attachment
  on(MemberActions.getAttachmentsSuccess, (state, {payload}) => {
    return {
      ...state,
      attachments: payload.items ?? []
    };
  }),
  on(MemberActions.clearAttachments, (state) => {
    return {
      ...state,
      attachments: null
    };
  }),
  on(MemberActions.downloadAttachmentSuccess, (state, {payload}) => {
    return {
      ...state,
      downloadedAttachment: payload
    };
  })
  //#endregion
);

export const memberReducers: ActionReducerMap<MemberState> = {
  data: dataReducer,
  error: errorReducer
};