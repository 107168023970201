import {
  trigger,
  transition,
  style,
  query,
  group,
  animateChild,
  animate
} from '@angular/animations'

const childToParentStages: string[] = [
    "ProfDetailScreen => ProfScreen",
    "BenefitPdfScreen => BenefitScreen",
    "DropPdfScreen => DropScreen",
    "SpdPdfScreen => SpdScreen",
    "DocPdfScreen => DocLibScreen",
    "DrilldownScreen => *",
    "DocumentScreen => *",
    "PdfViewScreen => *",
    "AttachmentListScreen => *",
    "RetireePayrollHistoryScreen => *",
];

export const routeTransitionAnimation = trigger('routeAnimations', [
  transition('* => SplashScreen', []),
  transition('SplashScreen => *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ],
      { optional: true }
    ),
    query(':enter', [style({ top: '100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(
        ':leave',
        [
          style({ height: '100%' }),
          animate('1s ease-out', style({ top: '-100%' }))
        ],
        { optional: true }
      ),
      query(':enter', [animate('1s ease-out', style({ top: '0%' }))], {
        optional: true
      })
    ]),
    query(':enter', animateChild(), { optional: true })
  ]),
  transition('* => CalcResultScreen', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ],
      { optional: true }
    ),
    query(':enter', [style({ top: '100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(
        ':leave',
        [
          style({ height: '100%' }),
          animate('1s ease-out', style({ top: '-100%' }))
        ],
        { optional: true }
      ),
      query(':enter', [animate('1s ease-out', style({ top: '0%' }))], {
        optional: true
      })
    ]),
    query(':enter', animateChild(), { optional: true })
  ]),
  transition('CalcResultScreen => *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%'
        })
      ],
      { optional: true }
    ),
    query(':enter', [style({ bottom: '100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(
        ':leave',
        [
          style({ height: '100%' }),
          animate('1s ease-out', style({ bottom: '-100%' }))
        ],
        { optional: true }
      ),
      query(':enter', [animate('1s ease-out', style({ bottom: '0%' }))], {
        optional: true
      })
    ]),
    query(':enter', animateChild(), { optional: true })
  ]),
  transition(childToParentStages.join(','), [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%'
        })
      ],
      { optional: true }
    ),
    query(':enter', [style({ right: '100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('500ms ease-out', style({ right: '-100%' }))], {
        optional: true
      }),
      query(':enter', [animate('500ms ease-out', style({ right: '0%' }))], {
        optional: true
      })
    ]),
    query(':enter', animateChild(), { optional: true })
  ]),
  transition('* => *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ],
      { optional: true }
    ),
    query(':enter', [style({ left: '100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('500ms ease-out', style({ left: '-100%' }))], {
        optional: true
      }),
      query(':enter', [animate('500ms ease-out', style({ left: '0%' }))], {
        optional: true
      })
    ]),
    query(':enter', animateChild(), { optional: true })
  ])
])