<!-- <div class="mainContent"> -->
<div #parentDiv class="deskto_p scroll">
  <div class="member_det mob_pad">
    <div class="esti_mate" *ngIf="estimatedPension">
      <div class="esti_m">
        <ol>
          <li>
            <strong>Your age at retirement</strong>
            <em>{{ estimatedPension.memberAge }}</em>
          </li>
          <li>
            <strong>{{ calcSalaryTitle }}</strong>
            <em>{{
              estimatedPension.salary | currency: 'USD':'symbol':'1.2-2'
            }}</em>
          </li>
          <li *ngIf="estimatedPension.yearsOfService">
            <strong>{{ protectedServiceText }}</strong>
            <em>{{ estimatedPension.yearsOfService }}</em>
          </li>
          <li *ngIf="hasBeneficiary && estimatedPension">
            <strong>Beneficiary age at retirement</strong>
            <em>{{ estimatedPension.beneficiaryAge }} Years old</em>
          </li>
        </ol>
      </div>
      <div class="estim_t">
        <button
          type="button"
          (click)="onRevise()"
          class="btn {{ buttonClass }} btn-lg ml-3"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
    <ng-container
      *ngIf="
        !estimationFailure && !estimationErrors.length && estimatedPension as ep
      "
    >
      <div class="row no-gutters py-3">
        <div class="col esti_head">
          <h1>{{ calcName }}</h1>
          <h2>{{ calcTitle }}</h2>
        </div>
      </div>
      <div class="row no-gutters" *ngIf="ep.showHeader != false">
        <div class="col-4"><h3>Option</h3></div>
        <div class="col"><h3 class="text-right">Monthly Income</h3></div>
      </div>
      <div class="esti_accord_main">
        <div
          class="row esti_accord no-gutters option py-2"
          (click)="toggleOption(op)"
          *ngFor="let op of ep.options"
        >
          <div class="col head_ing_esti">
            <div class="fa_icon">
              <fa-icon
                [icon]="faInfoCircle"
                class="fa-lg mr-1 align-middle"
                *ngIf="op.explanation"
              ></fa-icon>
              <label
                [class]="!op.isCollapsed && op.explanation ? 'expanded' : ''"
                >{{ op.name }}</label
              >
            </div>
            <div>
              <div class="col amount" *ngIf="op.formatType == 'Percent'">
                {{ op.amount | percent: '0.2' }}
              </div>
              <div class="col amount" *ngIf="op.formatType != 'Percent'">
                {{ op.amount | currency }}
              </div>
            </div>
          </div>
          <div class="w-100" *ngIf="op.explanation"></div>
          <div
            class="col mt-3 explanation"
            [ngbCollapse]="op.isCollapsed"
            [innerHtml]="safeHtml(op.explanation)"
            *ngIf="op.explanation"
          ></div>
          <div class="w-100"></div>
          <div
            class="col-12 spac_e"
            [style.visibility]="op.isCollapsed ? 'hidden' : 'visible'"
            *ngIf="op.ageFactor"
          >
            ({{ ep.accruedBenefit | currency }} {{ op.formula }}
            {{ op.ageFactor }})
          </div>
          <!-- <div class="col amount" *ngIf="op.formatType == 'Percent'">{{ op.amount | percent : '0.2' }}</div>
      <div class="col amount" *ngIf="op.formatType != 'Percent'">{{ op.amount | currency }}</div> -->
        </div>
      </div>
      <div class="row no-gutters" *ngIf="footerHtml">
        <div class="col pt-3">
          <small [innerHtml]="footerHtml"></small>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="estimationErrors.length">
      <ng-container *ngFor="let err of estimationErrors">
        <div class="row" *ngIf="err">
          <div class="col my-3 px-0">
            <div class="alert alert-danger" role="alert" [innerHtml]="err"></div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="estimationFailure">
      <div class="row">
        <div class="col mt-3 px-0">
          <div class="alert alert-danger" role="alert">
            There was an error estimating your pension options, please try
            again.
            <br /><br />If this message persists please contact support.
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        !estimatedPension && !estimationFailure && !estimationErrors.length
      "
    >
      <div class="row">
        <div class="col">
          <div class="sk-chase mx-auto mt-5">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
