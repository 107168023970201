import { createFeatureSelector, createSelector } from '@ngrx/store';
import { metadataFeatureKey } from './reducer';
import { MetadataState } from './state';

const selectMetadataState = createFeatureSelector<MetadataState>(metadataFeatureKey);

export const getAll = createSelector(selectMetadataState, (state:MetadataState) => state.data);
export const getItem = createSelector(selectMetadataState, (state:MetadataState, props) => state.data?.find(md => md.entityPropertyId === props.key));
export const resolveProperty = createSelector(selectMetadataState, (state:MetadataState, itemKey:string) => {
    if(!itemKey) {
        return null;
    }
    
    let mdItem = state.data?.find(md => (md.entityPropertyId === itemKey));
    
    return mdItem;
});