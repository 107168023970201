import { ErrorHandler, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CallboxComponent } from './components/callbox/callbox.component'
import { ProgressMaskComponent } from './components/progressMask/progressMask.component'
import { DetailDisplayComponent } from './components/detail-display/detail-display.component'
import { InlinePdfComponent } from './components/inline-pdf/inline-pdf.component'
import { ToastComponent } from './components/toast/toast.component'
import { PdfViewerModule } from 'ng2-pdf-viewer'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { ErrorHandlerService } from './services/logging/errorHandler.service'
import { LoggingService } from './services/logging/logging.service'
import { LoadingIndicatorService } from './services/util/loadingIndicator.service'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { RouterModule } from '@angular/router'
import { auth0Config } from './services/auth/auth0.config'
import {
  AuthGuard,
  AuthHttpInterceptor,
  AuthModule as Auth0Module,
  AuthService
} from '@auth0/auth0-angular'
import { AppDataInterceptor } from './services/auth/app-data-interceptor'
import { ReactiveFormsModule } from '@angular/forms'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { HttpConfigInterceptor } from '../interceptor/httpconfig.interceptor'

@NgModule({
  declarations: [
    CallboxComponent,

    ProgressMaskComponent,
    DetailDisplayComponent,
    InlinePdfComponent,
    ToastComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    PdfViewerModule,
    NgbModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    Auth0Module.forRoot({
      ...auth0Config.config
    })
  ],
  exports: [
    CallboxComponent,
    ProgressMaskComponent,
    DetailDisplayComponent,
    InlinePdfComponent,
    ToastComponent
  ],
  providers: [
    AuthService,
    AuthGuard,
    LoggingService,
    LoadingIndicatorService,
    { provide: HTTP_INTERCEPTORS, useClass: AppDataInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ]
})
export class SharedModule {}
