<div class="deskto_p">
  <div class="member_det mob_pad">
    <h1>{{ page.header }}</h1>

    <ng-container
      *ngFor="let content of page.content"
      [ngSwitch]="content.key"
      class="mem_infb"
    >
      <div
        class="row genericCallbox mem_inf"
        *ngSwitchCase="TEMPLATE_KEY.SupportContact"
      >
      <callbox *ngIf="content.value?.length">{{ content.value }}</callbox>
        <!-- <div
          class="header_banner_info"
          style="background: url(./assets/images/info.png)"
        >
          <psrs-contactbox *ngIf="content.value?.length">{{
            content.value
          }}</psrs-contactbox>
          <h2>
            {{ content.value }}
            <a href="tel:(617) 977-8408">(123) 456-7890</a>
            
          </h2>
        </div> -->
      </div>
      

      <div class="row topmargin15" *ngSwitchCase="TEMPLATE_KEY.Subheader">
        <h1 class="rowTitle col-12">{{ content.value }}</h1>
      </div>

      <!-- Details Table/List  -->

      <ng-container *ngSwitchCase="TEMPLATE_KEY.PropertyList">
        <!-- For Table View -->

        <ng-container class="for_grd" *ngIf="listDisplay === 'Table'">
          <div class="mem_container mem_table">
            <ng-container *ngFor="let item of propertyList; let i = index">
              <div class="mem_info">
                <div class="row topmargin15">
                  <h2>{{ item.property.propertyLabel }}</h2>
                </div>
                <div class="row col_value">
                  <div
                    class="col_value"
                    *ngIf="item.isAggregated; else elseBlock"
                  >
                    <h3>{{ item.value }}</h3>
                  </div>
                  <ng-template #elseBlock>
                    <ng-container [ngSwitch]="item.property.type">
                      <!-- Property Type Condition -->

                      <div
                        *ngSwitchCase="PROPERTY_TYPE.RichText"
                        class="view-rich-text-container"
                      >
                        <mat-icon>note_add</mat-icon>
                        <span (click)="showRichTextModel(item, showRichTextModel)">View</span>
                      </div>
                      <div *ngSwitchCase="PROPERTY_TYPE.Email">
                        <ptg-masked-eye
                          class="list-masked-container ng-star-inserted"
                          [masked]="item.property.config.masked"
                          [propertyValue]="item.value"
                        >
                        </ptg-masked-eye>
                      </div>
                      <div *ngSwitchCase="PROPERTY_TYPE.Phone">
                        <ptg-masked-eye
                          class="list-masked-container ng-star-inserted"
                          [masked]="item.property.config.masked"
                          [propertyValue]="item.value"
                        >
                        </ptg-masked-eye>
                      </div>
                      <div *ngSwitchCase="PROPERTY_TYPE.SSN">
                        <ptg-masked-eye
                          class="list-masked-container ng-star-inserted"
                          [masked]="item.property.config.masked"
                          [propertyValue]="item.value"
                        >
                        </ptg-masked-eye>
                      </div>

                      <!-- Display Property Values -->

                      <div *ngSwitchDefault class="col_value">
                        <h3
                          [innerHtml]="
                            getDisplayValue(item.property, item.value)
                          "
                        ></h3>
                        <!-- <h3 >Value</h3> -->
                      </div>
                    </ng-container>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
          <div
            class="rowColor topmargin30"
            *ngIf="hasAttachment(propertyListAttachmentConfig.sectionKey)"
          >
            <div class="row att_ach">
              <div class="rowTitle">
                <h2>{{ propertyListAttachmentConfig.label }}:</h2>
              </div>
              <div class="rightAlignData view-attachment-link">
                <h3
                  class="btns_mem"
                  (click)="
                    viewAttachmentList(propertyListAttachmentConfig.sectionKey)
                  "
                >
                  View List
                </h3>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- For List View  -->

        <ng-container class="for_grd" *ngIf="listDisplay === 'List'">
          <div class="mem_container">
            <ng-container *ngFor="let item of propertyList; let i = index">
              <div class="mem_info l_st_con">
                <div class="row topmargin15">
                  <h2>{{ item.property.propertyLabel }}</h2>
                </div>
                <div class="row col_value">
                  <div
                    class="col_value"
                    *ngIf="item.isAggregated; else elseBlock"
                  >
                    <h3>{{ item.value }}</h3>
                  </div>
                  <ng-template #elseBlock>
                    <ng-container [ngSwitch]="item.property.type">
                      <!-- Property Type Condition -->

                      <div
                        *ngSwitchCase="PROPERTY_TYPE.RichText"
                        class="view-rich-text-container"
                      >
                        <mat-icon>note_add</mat-icon>
                        <span (click)="showRichTextModel(item, rickTextModal)">View</span>
                      </div>
                      <div *ngSwitchCase="PROPERTY_TYPE.Email">
                        <ptg-masked-eye
                          class="list-masked-container ng-star-inserted"
                          [masked]="item.property.config.masked"
                          [propertyValue]="item.value"
                        >
                        </ptg-masked-eye>
                      </div>
                      <div *ngSwitchCase="PROPERTY_TYPE.Phone">
                        <ptg-masked-eye
                          class="list-masked-container ng-star-inserted"
                          [masked]="item.property.config.masked"
                          [propertyValue]="item.value"
                        >
                        </ptg-masked-eye>
                      </div>
                      <div *ngSwitchCase="PROPERTY_TYPE.SSN">
                        <ptg-masked-eye
                          class="list-masked-container ng-star-inserted"
                          [masked]="item.property.config.masked"
                          [propertyValue]="item.value"
                        >
                        </ptg-masked-eye>
                      </div>

                      <!-- Display Property Values -->

                      <div *ngSwitchDefault class="col_value">
                        <h3
                          [innerHtml]="
                            getDisplayValue(item.property, item.value)
                          "
                        ></h3>
                        <!-- <h3 >Value</h3> -->
                      </div>
                    </ng-container>
                  </ng-template>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="hasAttachment(propertyListAttachmentConfig.sectionKey)"
            >
              <div
                class="rowColor topmargin30"
                *ngIf="hasAttachment(propertyListAttachmentConfig.sectionKey)"
              >
                <div class="row att_ach">
                  <div class="rowTitle">
                    <h2>{{ propertyListAttachmentConfig.label }}</h2>
                  </div>
                  <div class="rightAlignData view-attachment-link">
                    <h3
                      class="btns_mem"
                      (click)="
                        viewAttachmentList(
                          propertyListAttachmentConfig.sectionKey
                        )
                      "
                    >
                      View List
                    </h3>
                  </div>
                </div>
              </div>

              <!-- <div class="row topmargin15">
                <h2>{{ propertyListAttachmentConfig.label }}</h2>
              </div>
              <div class="row view-attachment-link">
                <h3
                  (click)="
                    viewAttachmentList(propertyListAttachmentConfig.sectionKey)
                  "
                >
                  View List
                </h3>
              </div> -->
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <!--  -->
      <ng-container *ngSwitchCase="TEMPLATE_KEY.PropertyTable">
        <ng-container *ngIf="propertyTable">
          <div class="tabl_e">
            <table class="propertyTable topmargin30">
              <thead class="headerRow">
                <tr>
                  <td
                    style="width: 7%"
                    *ngIf="propertyTableAttachmentConfig.sectionKey"
                  >
                    <input
                      type="checkbox"
                      (change)="onChange($event)"
                      [checked]="allSelected"
                    />
                  </td>
                  <td *ngFor="let prop of propertyTable.properties">
                    {{ prop.propertyLabel }}
                  </td>
                  <td
                    class="attachment-file"
                    *ngIf="propertyTableAttachmentConfig.sectionKey"
                  >
                    {{ propertyTableAttachmentConfig.label }}
                  </td>
                </tr>
              </thead>
              <tbody class="rowColor">
                <ng-container *ngFor="let val of propertyTable.values">
                  <tr
                    [class.disable-row]="
                      propertyTableAttachmentConfig.sectionKey &&
                      !val.noOfAttachments
                    "
                  >
                    <td
                      style="width: 7%"
                      *ngIf="propertyTableAttachmentConfig.sectionKey"
                    >
                      <input
                        type="checkbox"
                        [class.hidden-checkbox]="!val.noOfAttachments"
                        (change)="onChange($event, val)"
                        [checked]="val.isSelected"
                      />
                    </td>
                    <td *ngFor="let prop of propertyTable.properties">
                      <span
                        [innerHtml]="
                          safeHtml(getDisplayValue(prop, val[prop.entityPropertyId], true))
                        "
                      ></span>
                    </td>
                    <td
                      class="attachment-file"
                      *ngIf="propertyTableAttachmentConfig.sectionKey"
                    >
                      <span
                        class="number-attachment"
                        [class.zero-attachment]="!val.noOfAttachments"
                        (click)="
                          viewAttachmentList(
                            propertyTableAttachmentConfig.sectionKey,
                            val
                          )
                        "
                      >
                        {{ val.noOfAttachments[0] }}
                      </span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <button
            type="button"
            [disabled]="!isSelected"
            *ngIf="propertyTableAttachmentConfig.sectionKey"
            class="btn btn-recalculate btn-lg"
            (click)="downloadMulti(propertyTableAttachmentConfig.sectionKey)"
          >
            <mat-icon>file_download</mat-icon>
            Download Selected
            <div class="lds-ring" *ngIf="isLoading">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
        </ng-container>
      </ng-container>

      <div
        class="row topmargin30 asteriskText ffoot_note"
        *ngSwitchCase="TEMPLATE_KEY.Footnote"
      >
        <span>{{ content.value }}</span>
      </div>
      <ng-template #rickTextModal let-modal>
        <div class="modal-header">
          <h4 class="modal-title">{{ richTextTitle }}</h4>
        </div>
        <div
          class="modal-body"
          [innerHtml]="richTextContent"
          style="max-height: 300px"
        ></div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-modal btn-lg"
            (click)="closeDisplay()"
          >
            Close
          </button>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
