import { Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { MatMenuItem } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { faBars, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { MemberSelectors } from 'src/app/member';
import { PagesDataActions, PagesSelectors } from 'src/app/pages';
import { ClientSelectors, FundType } from 'src/app/client';
import { MenuItem } from './menu-item-model';
import { MemberInfo } from 'src/app/member/models/member';
import { AppDataActions } from '../..';


@Component({
    selector: 'menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
    menuList: MenuItem[];
    menuIcon: IconDefinition = faBars;
    subs: Subscription = new Subscription();
    idx: number = 0;
    @ViewChildren('menuItems') private menuItemsRef: QueryList<MatMenuItem>;


    constructor(private store: Store, private router: Router) {
        this.menuList = [];
    }

    ngDestroy(): void {
        this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.subs.add(
            combineLatest([
                this.store.select(PagesSelectors.getPages),
                this.store.select(MemberSelectors.getMetadataAll),
                this.store.select(MemberSelectors.getMember),
                this.store.select(ClientSelectors.getClient),
            ]).subscribe(([pages, mmds, mb, client]) => {
                if(!(pages)) {
                    return;
                }
                let result = [];

                if (mb) {
                    const propertyName = Object.keys(mb?.memberInfo?.propertyList).find(item => item.includes('.Name'));
                    const propertyValue = mb?.memberInfo?.propertyList[propertyName];
                    const userName = this.getConcatString([propertyValue.first, propertyValue.middle, propertyValue.last], ' ');

                    localStorage.setItem('memberName', userName);
                    this.store.dispatch(AppDataActions.setUserName({ userName }));
                }
                
                pages
                    .filter(page => !(page.template === 'memberDetail' && page.content?.length === 0))
                    .forEach(pg => {
                    var menuItem: MenuItem = {
                        label: pg.navigation,
                        href: `pages/${pg.id}`
                    };
                    if (pg.template === 'memberDetail') {
                        menuItem.isHidden = Boolean(pg.config?.metadataKey);
                    }
                    if(pg.template === 'retirementCalc') {
                        if(client?.fundType === FundType.MobileAL){
                            menuItem.isHidden = !(mb?.status.toLocaleLowerCase() === 'active');
                        } else if (client?.fundType === FundType.ChicagoLabor) {
                            let contentValues = pg.content.filter(cnt => cnt.key.endsWith("Value") && cnt.parentKey === 'estimatorAccessibility')[0];
                            if(contentValues != null) {
                                var metadataKey = contentValues.value[0];
                                this.store.select(MemberSelectors.resolveMetadata, metadataKey).subscribe(mdProp => {
                                    menuItem.isHidden = mdProp != "true";
                                });
                            }
                
                        }
                        if (!(mb as MemberInfo)?.isMember) {
                            menuItem.isHidden = true;
                        }
                        localStorage.setItem('fundKey', client?.key);
                        localStorage.setItem('fundType', client?.fundType?.toString());
                    }
                    result.push(menuItem);
        
                    localStorage.setItem('fundKey', client?.key);
                });
                this.menuList = result;
            })
        );
    }

    getConcatString(values: string[], separator: string = ', ') {
        return values.filter(value => value).join(separator);
    }

    itemClick(item: MenuItem, i: number): void {
        this.store.dispatch(PagesDataActions.loadPages());
        this.idx = i;
        this.router.navigate([item.href], {
            fragment: window.location.getClientDomain()
        });

    }

    menuOpened(): void {
        this.menuIcon = faTimes;
        const item = this.menuItemsRef.find((item, index, array) => {
            return index == this.idx;
        });
        setTimeout(() => {
            item.focus();
        }, 0);
    }

    menuClosed(): void {
        this.menuIcon = faBars;
    }
}