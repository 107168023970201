import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { PagesModels } from 'src/app/pages';
import { LoggingService } from 'src/app/shared';

@Component({
  selector: 'psrs-accordion-template',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccordionComponent implements OnInit {
  private _page: PagesModels.PageDefinition;

  @Input() set page(value: PagesModels.PageDefinition) {
    this._page = value;
    this.redrawComponent();
  }

  get page(): PagesModels.PageDefinition {
    return this._page;
  }

  TEMPLATE_KEY: any = {
    Title: 'title',
    Content: 'content'
  };

  contentList:any[] = [];

  constructor(private sanitized: DomSanitizer, private loggingService: LoggingService) { }

  ngOnInit(): void {
  }

  redrawComponent(): void {
    let result = {};
    this.page.content.forEach(cnt => {
      for(var idx in cnt.value) {
        if(result[idx] == null) {
          result[idx] = {};
        }
        result[idx][cnt.key] = cnt.value[idx];
      }
    });

    this.contentList = [];
    Object.entries(result).filter(([key, value]) => key !== '$id').map(([key, value])=> this.contentList.push(value));
  }

  // safeHtml(html: string): SafeHtml {
  //   return this.sanitized.bypassSecurityTrustHtml(html);
  // }

  safeHtml (html: string): SafeHtml {  
    const modifiedHtml = `<div class="acco_rdian">${html}</div>`
    return this.sanitized.bypassSecurityTrustHtml(modifiedHtml)
  }

  panelChanged(event: NgbPanelChangeEvent): void {
    this.loggingService.logEvent(`Toggle ${this.page.header}`, {
        toggleState: event.nextState ? 'Open' : 'Closed'
    });
  }  
}