<div class="container">
  <div class="white_cover"></div>
  <div class="main_conten_t">
    <div class="side_bar_con" *ngIf="isLoggedIn">
      <psrs-sidebar></psrs-sidebar>
    </div>
    <div class="conten_t">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>

  </div>

  <div class="row">
    <toast></toast>
  </div>
  <div class="row header for desjk" *ngIf="isLoggedIn">
    <div class="men_u">
      <div class="companyWrapper">
        <div class="logo"><img [src]="logoUrl" alt="logo" /></div>
        <div class="companyDiv">
          <span class="companyName" id="companyName">{{ title }}</span>
        </div>
      </div>
      <div class="menuArea">
        <ng-container>
          <menu></menu>
        </ng-container>
      </div>
      <div class="row userInfo">
        <div class="username">
          <button *ngIf="isPensionAppOn" class="click-bell-button" mat-button (click)="clickBell()">
            <mat-icon *ngIf="showIconActive">notifications_active</mat-icon>
            <mat-icon *ngIf="!showIconActive">notifications_off</mat-icon>
          </button>
          <div class="user_name_main">
          <span *ngIf="impersonator" class="imp">{{ impersonator }} </span><span class="user_nm"
            [ngClass]="username === '(Unknown)' ? 'emptyUsername' : ''">{{
            username
            }}</span>
          </div>
        </div>
        <a class="logout" href="#" (click)="logout()">
          <fa-icon [icon]="falock" class="faMenuIcon"></fa-icon> Logout
        </a>
      </div>
    </div>
  </div>
</div>