import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import * as PageDataActions from './pages.actions';
import { PagesState } from './pages.state';

export const pagesFeatureKey = 'pages';
export const initialState: PagesState = { };

const errorReducer = createReducer({ }, 
  on(PageDataActions.loadPagesFailure, (state, { error }) => ({
    action: 'loadPages',
    error: error
  }))
);

const dataReducer = createReducer([ ],
  on(PageDataActions.loadPagesSuccess, (state, { pages }) => pages),
  on(PageDataActions.signAttachmentSuccess, (state, { pageId, fileKey, signature }) => {
    var newState = [...state];
    var pageIdx = newState.findIndex(x => x.id === pageId);
    var newPage = {
      ...newState[pageIdx],
      content: [...newState[pageIdx].content]
    };
    var contentIdx = newPage.content.findIndex(x => x.key === 'attachment');
    if(contentIdx < 0) {
      contentIdx = newPage.content.findIndex(x => x.key === 'file');
    }

    if(newPage.isBundle) {
      var fileIdx;
      var files = {...newPage.content[contentIdx].value};
      for(let idx in files) {
        if(files[idx].key === fileKey) {
          fileIdx = idx;
          break;
        }
      }
      var file = {
        ...files[fileIdx],
        signature
      };

      files[fileIdx] = file;
      newPage.content[contentIdx] = {
        ...newPage.content[contentIdx],
        value: files
      };
    } else {
      var cnts = [...newPage.content];
      cnts[contentIdx] = {
        ...cnts[contentIdx],
        value: {
          ...cnts[contentIdx].value,
          signature
        }
      };
      newPage.content = cnts;
    }

    newState[pageIdx] = newPage;
    return newState;
  }),

  
);

const benefitListReducer = createReducer({data: undefined}, 
  on(PageDataActions.getBenefitList, (state) => {
    return {
      ...state,
      data: undefined
    }
  }),
  on(PageDataActions.getBenefitListSuccess, (state, {response}) => {
    return {
      ...state,
      data: response
    }
  }),
  on(PageDataActions.getBenefitListFailure, (state) => {
    return {
      ...state,
      data: undefined
    }
  }),
)

export const pagesReducers: ActionReducerMap<PagesState> = {
  data: dataReducer,
  error: errorReducer,
  benefitList: benefitListReducer
};